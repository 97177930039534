@media (max-width: 962px) {
  .nameEmailBox {
    flex-wrap: wrap;
  }
}

@media (max-width: 601px) {
  .nameEmailBox {
    flex-direction: column;
  }

  .inputNameEmail {
    width: 100%;
  }
}
