.menuLink {
  position: sticky;
  color: white;
  transition: margin-top 0.2s ease, top 0.2s ease;
}

.menuTitle {
  display: none;
  position: absolute;
  color: white;
  background-color: gray;
  /* height: 1rem;
  width: 3rem; */
  top: -0.4rem;
  right: 4rem;
  padding: 0.3rem;
  border-radius: 0.4rem;
}

.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 3rem;
  height: 3rem;
  background-color: rgb(255, 153, 102);
  border-radius: 50%;
  border: none;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: bold;
  /* font-size: large; */
}

.menuButton:hover {
  cursor: pointer;
}

.menuLink:hover .menuTitle {
  /* .menuLink.hovered .menuTitle { */
  display: block;
}

@media (max-width: 601px) {
  .menuTitle {
    display: block;
  }
}
