.diagonalTextL {
  display: flex;
}

.diagonalTextS {
  display: none;
}

.p1 {
  width: calc(238px + var(--x));
  margin-top: 0px;
}

.p2 {
  width: calc(226px + var(--x));
}

.p3 {
  width: calc(214px + var(--x));
}
.p4 {
  width: calc(202px + var(--x));
}
.p5 {
  width: calc(190px + var(--x));
}
.p6 {
  width: calc(178px + var(--x));
}
.p7 {
  width: calc(166px + var(--x));
  margin-bottom: 0.3rem;
}
.p8 {
  width: calc(154px + var(--x));
  margin-bottom: 0.3rem;
}
.p9 {
  width: calc(142px + var(--x));
  margin-bottom: 0.3rem;
}

.p10 {
  width: calc(130px + var(--x));
  margin-bottom: 0.3rem;
}

.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9,
.p10 {
  text-align: justify;
  height: 0.5rem;
  padding-right: 0.3rem;
}

.aboutImageWrapper {
  width: 100%;
  margin-left: -1rem;
  margin-right: -20rem;
  transform: skew(-17deg);
  overflow: hidden;
}

.aboutImage {
  margin-left: -4.5rem;
  width: 100%;
  height: 100%;
  transform: skew(17deg);
  background-position-y: 70%;
  background-size: cover;
}

@media (max-width: 1920px) {
  .p1,
  .p2,
  .p3,
  .p4,
  .p5,
  .p6,
  .p7,
  .p8,
  .p9,
  .p10 {
    --x: 450px;
  }

  .aboutImageWrapper {
    transform: skew(-17deg);
    margin-left: -1rem;
  }

  .aboutImage {
    transform: skew(17deg);
  }
}

@media (max-width: 1279px) {
  /* .aboutContainer {
    font-size: 1rem;
  } */

  .p1,
  .p2,
  .p3,
  .p4,
  .p5,
  .p6,
  .p7,
  .p8,
  .p9,
  .p10 {
    --x: 200px;
  }

  .aboutImageWrapper {
    transform: skew(-27deg);
  }

  .aboutImage {
    transform: skew(27deg);
  }
}

@media (max-width: 600px) {
  .aboutImageWrapper {
    margin-left: -2.5rem;
    /* margin-right: -20rem; */
  }

  .aboutImage {
    margin-left: -9rem;
    width: 100%;
    height: 100%;
    background-color: blue;
    transform: skew(26deg);
    background-position-y: 70%;
    background-size: cover;
  }

  .diagonalTextL {
    display: none;
  }

  .diagonalTextS {
    display: flex;
  }
  /* .aboutContainer {
    font-size: 0.8rem;
  } */

  .p1,
  .p2,
  .p3,
  .p4,
  .p5,
  .p6,
  .p7,
  .p8,
  .p9,
  .p10 {
    --x: 20px;
  }
  /* 
  .aboutImageWrapper {
    transform: skew(-30deg);
  }

  .aboutImage {
    transform: skew(30deg);
  } */
}

@media (max-width: 500px) {
  /* .p1,
  .p2,
  .p3,
  .p4,
  .p5,
  .p6,
  .p7,
  .p8,
  .p9 {
    --x: 20px;
  }

  .aboutImageWrapper {
    transform: skew(-33deg);
    margin-left: -2rem;
  }

  .aboutImage {
    transform: skew(33deg);
  }

  .myBrandLogosCard {
    flex-direction: column;
  } */

  /* .aboutContainer {
    font-size: 0.6rem;
  } */
}
