.hamburgerMenuButton {
  display: block;
  justify-self: end;
  width: 30px;
  /* margin-right: 1rem; */
}

.firstLine {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  /* background-color: rgb(32, 31, 31); */
  /* background-color: rgb(44, 97, 144); */
  background-color: white;
  transform-origin: left;
  transition: 0.5s;
}

.firstLineAngle {
  transform: translateX(2px) rotate(45deg) translateY(-2px);
}

.secondLine {
  height: 4px;
  /* width: 22px; */
  width: 100%;
  border-radius: 2px;
  /* background-color: rgb(32, 31, 31); */
  /* background-color: rgb(44, 97, 144); */
  background-color: white;
  margin: 5px 0 5px 0;
  transition: 0.5s;
}

.secondLineAngle {
  opacity: 0;
}

.thirdLine {
  height: 4px;
  /* width: 22px; */
  width: 100%;
  border-radius: 2px;
  /* background-color: rgb(32, 31, 31); */
  /* background-color: rgb(44, 97, 144); */
  background-color: white;
  transition: 0.5s;
  transform-origin: left;
}

.thirdLineAngle {
  transform: translateX(2px) rotate(-45deg) translateY(2px);
}
