.App {
  box-sizing: border-box;
  max-width: 1920px;
  position: relative;
  margin: auto;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: clip;
  /* padding-bottom: calc(2*var(--p1));  height of footer */
  padding-bottom: 2rem; /* height of footer */
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: lightblue;
  padding-top: 7rem;
  color: white;

  background-image: url('back.JPG');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

:root {
  --x: 450px;
}

.p1 {
  width: calc(238px + var(--x));
  margin-top: 0px;
}

.p2 {
  width: calc(226px + var(--x));
}

.p3 {
  width: calc(214px + var(--x));
}
.p4 {
  width: calc(202px + var(--x));
}
.p5 {
  width: calc(190px + var(--x));
}
.p6 {
  width: calc(178px + var(--x));
}
.p7 {
  width: calc(166px + var(--x));
  margin-bottom: 0.3rem;
}

.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9 {
  text-align: justify;
  height: 0.5rem;
  padding-right: 0.3rem;
}

.aboutImageWrapper {
  width: 100%;
  margin-left: -1rem;
  margin-right: -20rem;
  background-color: green;
  transform: skew(-17deg);
  overflow: hidden;
}

.aboutImage {
  margin-left: -4.5rem;
  width: 100%;
  height: 100%;
  background-color: blue;
  transform: skew(17deg);
  background-position-y: 70%;
  background-size: cover;
}

@media (max-width: 1280px) {
  .App {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .App {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .App {
    font-size: 1rem;
  }
}
