@media (max-width: 800px) {
  .brandLogos {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .myBrandLogosCard {
    flex-direction: column;
  }
}
