.projectCard {
}

.cardImage {
  transition: transform 0.1s ease;
}

.cardImage:hover {
  cursor: pointer;
  transform: scale(1.03, 1.03);
}

@media (max-width: 800px) {
  .projects {
    flex-direction: column;
  }
}
